.broadcast-age-grid {
  display: grid;
  grid-template-columns: auto 1fr auto 1fr;
  grid-gap: 10px;
}


.bx-show{
  border: solid 1px #333;
  border-radius: 6px;
  padding: 10px;
}

.bx-show{
  display: table;
  width: 100%;
}

.logo-board{
  display: table-cell;
  vertical-align: middle;
  width: 20%;
  text-align: center;
}

.logo-icon{
  width: 50px;
  height: 50px;
}

.detail-board{
  display: table-cell;
  vertical-align: middle;
  padding-left: 10px;
}

.title-board{
  font-weight: bold;
  margin: 0;
}

.body-board{
  margin: 0;
}