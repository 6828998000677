.customer-view-container-grid {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 60px;
  }
  .customer-view-info {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 0 30px;
  }
  .customer-view-reset-pw {
    margin-top: 10px;
  }
  .customer-view-reset-pw div {
    width: 150px;
    height: 32px;
  }
  .customer-view-credit-grid {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 10px;
    width: fit-content;
    margin: 0 auto;
  }
  .customer-view-header-grid {
    display: grid;
    grid-gap: 10px;
    width: fit-content;
    height: 32px;
    margin: 0 0 20px auto;
  }
  .customer-view-header-grid-false {
    grid-template-columns: 130px;
  }
  .customer-view-header-grid-true {
    grid-template-columns: 130px 130px;
  }
  .customer-view-profile-image > .ant-avatar {
    width: 230px;
    height: 230px;
  }
  
  @media (max-width: 768px) {
    .customer-view-container-grid {
      grid-template-columns: 1fr;
      grid-gap: 20px;
    }
    .customer-view-profile-image > .ant-avatar {
      width: 200px;
      height: 200px;
    }
    .customer-view-info-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .customer-view-reset-pw {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  
  @media (max-width: 480px) {
    .customer-view-container-grid {
      grid-gap: 10px;
      font-size: 14px;
      padding-bottom: 10px;
    }
    .customer-view-header-grid {
      width: 100%;
      grid-gap: 5px;
      font-size: 14px;
    }
    .customer-view-header-grid-true {
      grid-template-columns: 1fr 1fr;
    }
    .customer-view-profile-image > .ant-avatar {
      width: 180px;
      height: 180px;
    }
    .customer-view-info {
      grid-gap: 0 20px;
    }
  }
  