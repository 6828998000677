.loading-container {
  position: absolute;
  width: 100%;
  height: 100%;
}
.loading-relative {
  position: relative;
  width: 100%;
  height: 100%;
}
.loading-bg-drop {
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.8;
}
.loading-icon {
  position: absolute;
  line-height: 0;
  font-size: 40px;
  color: #00adef;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}
