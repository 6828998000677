.dash-select-date-container-grid {
  display: grid;
  grid-template-columns: auto 450px 40px 1fr;
  grid-gap: 10px;
  margin-bottom: 20px;
}
.dash-big-title {
  font-size: 30px;
  margin: 20px 0 5px;
}
.dash-chart-box-container {
  box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
  background-color: white;
  margin-top: 30px;
  overflow: hidden;
}
.dash-chart-title {
  text-align: center;
  font-size: 20px;
}
.dash-card-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}
.dash-duo-chart-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}
.dash-space-between {
  padding: 40px;
}

@media (max-width: 1280px) {
  .dash-big-title {
    font-size: 28px;
  }
  .dash-card-grid {
    grid-gap: 25px;
  }
  .dash-duo-chart-grid {
    grid-gap: 25px;
  }
  .dash-chart-box-container {
    margin-top: 25px;
  }
  .dash-space-between {
    padding: 25px;
  }
  .dash-chart-title {
    font-size: 18px;
    margin-bottom: 5px;
  }
}

@media (max-width: 768px) {
  .dash-select-date-container-grid {
    font-size: 14px;
    grid-template-columns: auto 1fr 40px;
    grid-gap: 5px;
  }
  .dash-big-title {
    font-size: 24px;
  }
  .dash-card-grid {
    grid-gap: 20px;
  }
  .dash-report-card-padding {
    padding: 10px 15px;
  }
  .dash-duo-chart-grid {
    grid-gap: 20px;
  }
  .dash-chart-box-container {
    margin-top: 20px;
  }
  .dash-space-between {
    padding: 20px;
  }
  .dash-chart-title {
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  .dash-card-grid {
    grid-template-columns: 1fr;
  }
  .dash-duo-chart-grid {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
  .dash-table-container
    > .ant-table-wrapper
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-table.ant-table-small {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .dash-big-title {
    font-size: 20px;
    margin: 15px 0 5px;
  }
  .dash-select-date-container-grid {
    grid-template-columns: 1fr;
  }
  .dash-select-date-search {
    height: 32px;
  }
  .dash-card-grid {
    grid-gap: 10px;
  }
  .dash-chart-box-container {
    margin-top: 10px;
  }
  .dash-space-between {
    padding: 10px;
  }
}
