/* flex center center */
.f-c-c {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* flex start center */
.f-s-c {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
/* flex end center */
.f-e-c {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
/* flex start start */
.f-s-s {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.fl-wr-wr {
  flex-wrap: wrap;
}
/* container without navigation */
.ctn-wo-nvgt {
  margin-left: 300px;
  padding: 30px 40px;
  overflow: hidden;
  transition: 0.5s ease-in-out;
}
/* pagination style */
.pgnt-st {
  text-align: center;
  margin-top: 10px;
}
.pst-rlt {
  position: relative;
}
.pst-rlt-h-100pc {
  position: relative;
  height: 100%;
}
.pst-rlt {
  position: relative;
}
.t-ovfl-el {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* default button style */
.d-b-t-n-s {
  border-radius: 2px;
  color: white;
  font-weight: bolder;
  padding: 0 10px;
  text-align: center;
  cursor: pointer;
}
/* btn container only icon */
.b-c-o-i {
  line-height: 0;
  height: 30px;
  font-size: 20px;
}
.b-c-o-i-m {
  line-height: 0;
  height: 15px;
  width: 100%;
  font-size: 10px;
}
.d-bg-c-t {
  background-color: #3f4d67;
}
.cs-pt {
  cursor: pointer;
}
.cl-wh {
  color: white;
}
.h-g-0 {
  height: 0;
}
.h-32 {
  height: 32px;
}
.mg-t-5 {
  margin-top: 5px;
}
.mg-t-10 {
  margin-top: 10px;
}
.mg-t-20 {
  margin-top: 20px;
}
.mg-t-30 {
  margin-top: 30px;
}
.mg-t-40 {
  margin-top: 40px;
}
.mg-t-50 {
  margin-top: 50px;
}
.mg-bt-20 {
  margin-bottom: 20px;
}
.t-al-c {
  text-align: center;
}
.ovf-hd {
  overflow: hidden;
}
.ovf-sc {
  overflow: scroll;
}
/* padding payment channel card */
.p-d-p-ch-10-20 {
  padding: 10px 20px;
}
/* card container style */
.c-c-t-s {
  box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
  background-color: white;
}
/* header table grid */
.h-d-t-g {
  display: grid;
  grid-template-columns: 30px 1fr auto;
  grid-gap: 10px;
  font-size: 22px;
  margin-bottom: 5px;
}
/* header table grid with filter dropdown and search */
.h-d-t-g-w-d-d-s {
  display: grid;
  grid-template-columns: 7fr 14fr 7fr 2fr 5fr;
  grid-gap: 10px;
  margin-bottom: 20px;
}
/* header table grid upload slip */
.h-d-t-g-u-p {
  display: grid;
  grid-template-columns: 7fr 7fr 7fr 7fr 5fr;
  grid-gap: 10px;
  margin-bottom: 20px;
}
.h-d-t-p {
  font-size: 40px;
  font-weight: bolder;
  line-height: 1.2;
}
/* Set Or Edit Comfig Machine */
.e-d-t-p {
  font-size: 25px;
  font-weight: bolder;
  line-height: 1.2;
  margin-top: 18px;
}
/* title max content and input grid */
.t-m-c-i-p-g {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 10px;
}
/* header : date - icon - blank */
.h-d-i-b {
  display: grid;
  grid-template-columns: 1fr 40px;
  grid-gap: 10px;
  max-width: 550px;
}
/* suffix over input */
.s-f-o-i-p {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translate(0, -50%);
  font-size: 14px;
}
/* save or edit btn */
.s-e-b {
  height: 35px;
  margin-top: 30px;
}
.s-e-b div {
  height: 100%;
}
/* modal title icon */
.m-t-i-c {
  font-size: 80px;
  text-align: center;
  line-height: 0;
}
/* modal title text question */
.m-t-t-q {
  text-align: center;
  font-weight: bolder;
  font-size: 20px;
}
/* modal btn yes no grid */
.m-b-y-n-g {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  margin: 20px auto 0;
  max-width: 300px;
}
.m-b-y-n-g div {
  font-size: 16px;
}
/* table column action */
.tb-cl-a div:not(:first-child) {
  margin-left: 5px;
}
/* table column action grid for more than 1 line */
.tb-cl-a-g {
  display: grid;
  grid-gap: 5px;
}
.tb-cl-a-g-rp-2 {
  grid-template-columns: repeat(2, 1fr);
}
.tb-cl-a-g-rp-3 {
  grid-template-columns: repeat(3, 1fr);
}
/* display image list and input file */
.dpl-im-l-a-ip-f {
  display: grid;
  grid-template-columns: repeat(auto-fill, 110px);
  grid-gap: 10px;
}
/* display image list delete icon */
.dpl-im-l-dl-ic {
  position: absolute;
  top: -10px;
  right: -10px;
  line-height: 0;
  height: 25px;
  width: 25px;
  border-radius: 25px;
  background-color: black;
  opacity: 0.7;
  color: white;
  z-index: 1;
  cursor: pointer;
  font-size: 20px;
}
/* modal btn only cancel */
.md-btn-ol-cc {
  height: 32px;
  margin: 20px auto 0;
  width: 100px;
}
/* modal container carousel image */
.md-ctn-crs-im-l {
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  height: 500px;
  width: 100%;
  background-color: #3f4d67;
}
.md-ctn-crs-im-ar {
  color: white;
  font-size: 30px;
  line-height: 0;
}
.md-ctn-crs-im-ar i {
  cursor: pointer;
}
.md-ctn-crs-im {
  height: 500px;
}
.md-ctn-crs-im img {
  max-width: 100%;
  max-height: 100%;
}
.cl-g {
  color: #262626;
}
.cl-g:hover {
  color: #a6a6a6;
}

/* -------------------------------------------------- PURE ANTD -------------------------------------------------- */
.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  color: unset !important;
}

/* -------------------------------------------------- ANTD -------------------------------------------------- */
.antd-sl-al-bd > .ant-select > .ant-select-selector {
  border-color: #ec1c24 !important;
}
/* avatar with alert */
.antd-avt-al > .ant-avatar {
  border: 1px solid transparent;
  transition: all 0.3s;
}
.antd-avt-al-bd > .ant-avatar {
  border-color: #ec1c24;
}
.antd-chb-fz > .ant-checkbox-wrapper {
  font-size: 16px;
}
/* input group alert */
.antd-ip-g-al-bd > .ant-input-group-wrapper > .ant-input-group > .ant-input {
  border-color: #ec1c24;
}
.antd-ip-dab > .ant-input[disabled] {
  color: unset;
}

/* -------------------------------------------------- RESPONSIVE -------------------------------------------------- */
@media (max-width: 1280px) {
  .h-d-t-g {
    grid-gap: 5px;
    font-size: 20px;
  }
  .h-d-t-g-w-d-d-s {
    grid-template-columns: 14fr 28fr 14fr 3fr 10fr;
    grid-gap: 5px;
  }
  .h-d-t-g-w-d-d-s-order-4 {
    padding: 0 5px;
  }
  .h-d-t-g-w-d-d-s-order-5 {
    padding: 0 5px;
    font-size: 14px;
  }
}

@media (max-width: 1024px) {
  .ctn-wo-nvgt {
    margin-left: 60px;
    padding: 30px;
  }
  .h-d-t-g-w-d-d-s {
    grid-template-columns: 7fr 14fr 7fr 2fr 6fr;
    grid-gap: 5px;
  }
}

@media (max-width: 768px) {
  .ctn-wo-nvgt {
    padding: 20px;
  }
  .h-d-t-g {
    font-size: 18px;
  }
  .h-d-t-g-w-d-d-s {
    grid-template-columns: 5fr 5fr 1fr 4fr;
  }
  .h-d-t-g-w-d-d-s-order-1 {
    order: 1;
    grid-column: 1 / 5;
  }
  .h-d-t-g-w-d-d-s-order-2 {
    order: 2;
  }
  .h-d-t-g-w-d-d-s-order-3 {
    order: 3;
  }
  .h-d-t-g-w-d-d-s-order-4 {
    order: 4;
  }
  .h-d-t-g-w-d-d-s-order-5 {
    order: 5;
  }
  .p-d-p-ch-10-20 {
    padding: 10px 15px;
  }
  .h-d-t-p {
    font-size: 35px;
  }
}

@media (max-width: 600px) {
  .h-d-t-g {
    font-size: 16px;
  }
  .h-d-t-g-w-d-d-s {
    grid-template-columns: 5fr 5fr 1fr;
  }
  .h-d-t-g-w-d-d-s-order-1 {
    grid-column: 1 / 4;
  }
  .h-d-t-g-w-d-d-s-order-5 {
    grid-column: 1 / 4;
    padding: 3px 0;
  }
  .h-d-i-b {
    grid-gap: 5px;
  }
}

@media (max-width: 480px) {
  .ctn-wo-nvgt {
    padding: 10px;
  }
  .pgnt-st > .ant-pagination {
    font-size: 12px;
  }
  .h-d-t-g {
    grid-template-columns: 20px 1fr;
  }
  .h-d-t-g-w-d-d-s {
    grid-template-columns: 5fr 1fr;
  }
  .h-d-t-g-w-d-d-s-order-1 {
    grid-column: 1 / 3;
  }
  .h-d-t-g-w-d-d-s-order-2 {
    grid-column: 1 / 3;
  }
  .h-d-t-g-w-d-d-s-order-5 {
    grid-column: 1 / 3;
  }
  .t-m-c-i-p-g {
    grid-template-columns: 1fr;
  }
}
