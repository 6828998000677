/* -------------------------------------------------- TOPUP -------------------------------------------------- */
.pro-topup-get-more-grid {
  display: grid;
  grid-template-columns: 1fr 80px;
  grid-gap: 10px;
}

/* -------------------------------------------------- REWARD -------------------------------------------------- */
.pro-reward-header-grid {
  display: grid;
  grid-gap: 10px;
  margin-bottom: 20px;
}
.pro-reward-header-grid-column-false {
  grid-template-columns: 10fr 1fr 3fr 3fr;
}
.pro-reward-header-grid-column-true {
  grid-template-columns: 10fr 1fr 3fr;
}

/* -------------------------------------------------- RESPONSIVE -------------------------------------------------- */
@media (max-width: 768px) {
  .pro-reward-header-grid {
    grid-gap: 5px;
  }

  .pro-reward-header-grid-column-false {
    grid-template-columns: 1fr auto auto auto;
  }
}

@media (max-width: 600px) {
  .pro-reward-header-grid-column-true {
    grid-template-columns: 1fr auto auto;
  }
}

@media (max-width: 480px) {
  .pro-reward-header-grid {
    font-size: 14px;
  }
  .pro-reward-header-grid-column-false {
    grid-template-columns: 5fr 4fr 1fr;
  }
  .pro-reward-header-grid-column-true {
    grid-template-columns: 1fr auto;
  }
  .pro-reward-header-grid-search-false {
    grid-column: 1 / 3;
  }
  .pro-reward-header-grid-export-false {
    grid-column: 2 / 4;
  }
  .pro-reward-header-grid-export-true {
    grid-column: 1 / 3;
  }
}
