.machine-list-container-grid {
  display: grid;
  grid-gap: 10px;
  margin-bottom: 20px;
}
.machine-list-container-grid-superadmin-false {
  grid-template-columns: auto auto 4fr auto 2fr 2fr 2fr;
}
.machine-list-container-grid-superadmin-true {
  grid-template-columns: auto auto 7fr 40px 4fr;
}
.machine-list-container-grid-branchadmin-false,
.machine-list-container-grid-branchadmin-true {
  grid-template-columns: auto 5fr 40px;
  width: 100%;
  max-width: 600px;
}
.machine-list-row-dragging {
  background: white;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  font-size: 14px;
  padding-left: 10px;
}
.machine-list-row-dragging td {
  padding: 16px;
  visibility: hidden;
}
.machine-list-row-dragging .machine-list-drag-visible {
  visibility: visible;
}
.machine-list-drag-visible {
  line-height: 0;
}
.machine-list-container-machine-flex {
  display: flex;
  flex-wrap: wrap;
}
.machine-list-container-machine-card {
  padding: 20px;
  margin: 10px 10px 0 0;
  width: 300px;
  font-weight: bolder;
}
.machine-list-container-machine-card div:not(:first-child) {
  margin-top: 10px;
}
.machine-list-container-machine-image img {
  width: 200px;
  height: 200px;
}
.machine-list-select-width {
  width: 200px;
}

/* -------------------------------------------------- TYPE -------------------------------------------------- */
.machine-type-header-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  height: 32px;
  width: 300px;
  margin: 0 0 20px auto;
}

/* -------------------------------------------------- ADD MACHINE -------------------------------------------------- */
.machine-add-container-grid {
  display: grid;
  grid-template-columns: 140px 1fr;
  grid-gap: 10px;
}
.machine-add-brand-model-grid {
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-gap: 10px;
}
.machine-add-container-grid-with-two {
  display: grid;
  grid-template-columns: 17fr 13fr;
  grid-gap: 10px;
  margin-bottom: 10px;
}
.machine-add-dropdown-add-new-grid {
  display: grid;
  grid-template-columns: 5fr 1fr;
  grid-gap: 10px;
  padding: 5px 10px;
}
.machine-add-product-grid {
  display: grid;
  grid-template-columns: 10fr 10fr 50px 30px;
  grid-gap: 10px;
  max-width: 400px;
  margin-left: 10px;
}
.machine-add-product-grid:not(:first-child) {
  margin-top: 5px;
}
.machine-add-product-icon {
  font-size: 20px;
  line-height: 0.8;
  cursor: pointer;
}

/* -------------------------------------------------- HISTORY -------------------------------------------------- */
.machine-history-table-title-grid {
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-gap: 10px;
  font-size: 22px;
  margin-bottom: 5px;
}
/* .machine-history-table-search-grid {
  display: grid;
  grid-template-columns: 1fr 40px 1fr;
  grid-gap: 10px;
  margin-bottom: 10px;
} */

/* -------------------------------------------------- RESPONSIVE -------------------------------------------------- */
@media (max-width: 1280px) {
  .machine-list-container-grid {
    grid-gap: 5px;
    font-size: 14px;
  }
  .machine-list-container-grid-superadmin-false {
    grid-template-columns: auto auto 1fr auto auto auto;
  }
  .machine-list-select-width {
    width: 150px;
  }
}

@media (max-width: 768px) {
  .machine-list-container-grid {
    font-size: 16px;
  }
  .machine-list-container-grid-superadmin-false,
  .machine-list-container-grid-superadmin-true {
    grid-template-columns: 5fr 4fr 1fr;
  }
  .machine-list-container-grid-superadmin-select-site {
    grid-column: 2 / 4;
  }
  .machine-list-container-grid-superadmin-search {
    grid-column: 1 / 3;
  }
  .machine-list-container-grid-superadmin-export-1 {
    grid-column: 1 / 4;
  }
  .machine-list-container-grid-superadmin-export-2 {
    grid-column: 2 / 4;
  }
  .machine-list-select-width {
    width: 100%;
  }
  .machine-add-container-grid-with-two {
    grid-template-columns: 17fr 11fr;
  }
  .machine-list-container-machine-card {
    width: 240px;
  }
  .machine-list-container-machine-image img {
    width: 180px;
    height: 180px;
  }
}

@media (max-width: 600px) {
  .machine-add-container-grid-with-two {
    grid-template-columns: 1fr;
  }
  .machine-add-container-grid-with-two-order-1 {
    order: 1;
  }
  .machine-add-container-grid-with-two-order-2 {
    order: 2;
  }
  .machine-add-container-grid-with-two-order-3 {
    order: 3;
  }
  .machine-add-container-grid-with-two-order-4 {
    order: 4;
  }
  .machine-add-brand-model-grid {
    grid-template-columns: 140px 1fr;
  }
}

@media (max-width: 480px) {
  .machine-add-container-grid,
  .machine-add-brand-model-grid {
    grid-template-columns: 1fr;
  }
  .machine-type-header-grid {
    font-size: 14px;
    grid-gap: 5px;
    width: 100%;
    margin: 0 0 20px 0;
  }
  .machine-list-container-grid-superadmin-select-type,
  .machine-list-container-grid-superadmin-select-site {
    grid-column: 1 / 4;
  }
  .machine-list-container-grid-branchadmin-select-type {
    grid-column: 1 / 4;
  }
  .machine-list-container-grid-branchadmin-search {
    grid-column: 1 / 3;
  }
}
