.transaction-reconcile-card-container-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin-top: 20px;
  }
  .transaction-reconcile-card {
    padding: 20px;
    text-align: center;
  }
  .transaction-reconcile-card-title {
    font-size: 20px;
    font-weight: bolder;
  }
  .transaction-reconcile-card-value {
    font-size: 18px;
  }
  
  @media (max-width: 1024px) {
    .transaction-reconcile-card-container-grid {
      grid-gap: 10px;
    }
    .transaction-reconcile-card {
      padding: 10px;
    }
  }
  
  @media (max-width: 768px) {
    .transaction-reconcile-card-title {
      font-size: 18px;
    }
    .transaction-reconcile-card-value {
      font-size: 16px;
    }
  }
  
  @media (max-width: 600px) {
    .transaction-reconcile-card-container-grid {
      grid-template-columns: repeat(2, 1fr);
    }
    .transaction-reconcile-card-title {
      font-size: 16px;
    }
    .transaction-reconcile-card-value {
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    .transaction-reconcile-card-container-grid {
      grid-template-columns: 1fr;
      margin-top: 10px;
    }
  }
  