body {
  font-family: "Anuphan-Regular" !important;
  margin: 0;
  font-size: 16px !important;
  background: #f4f7fa !important;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

hr {
  margin: 0;
}

a {
  all: unset;
  color: currentColor !important;
}
