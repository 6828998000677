.h-d-t-g-w-d-d-s-washer {
  display: grid;
  grid-template-columns: 14fr 7fr 2fr 5fr;
  grid-gap: 10px;
  margin-bottom: 20px;
}

.customer-view-container-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 60px;
}
.customer-view-info {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 0 30px;
}
.customer-view-reset-pw {
  margin-top: 10px;
}
.customer-view-reset-pw div {
  width: 150px;
  height: 32px;
}
.customer-view-credit-grid {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 10px;
  width: fit-content;
  margin: 0 auto;
}
.customer-view-header-grid {
  display: grid;
  grid-gap: 10px;
  width: fit-content;
  height: 32px;
  margin: 0 0 20px auto;
}
.customer-view-header-grid-false {
  grid-template-columns: 130px;
}
.customer-view-header-grid-true {
  grid-template-columns: 130px 130px;
}
.customer-view-profile-image > .ant-avatar {
  width: 230px;
  height: 230px;
}

@media (max-width: 1280px) {
  .report-container-grid-superadmin-false,
  .report-container-grid-superadmin-true,
  .report-container-grid-branchadmin-false {
    grid-template-columns: 310px 1fr auto auto;
  }
}

@media (max-width: 1024px) {
  .report-container-grid {
    grid-gap: 5px;
  }
  .report-container-grid-branchadmin-true {
    grid-template-columns: 310px 1fr auto;
  }
}

@media (max-width: 768px) {
  .customer-view-container-grid {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
  .customer-view-profile-image > .ant-avatar {
    width: 200px;
    height: 200px;
  }
  .customer-view-info-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .customer-view-reset-pw {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 600px) {
  .report-container-grid-branchadmin-true {
    grid-template-columns: 1fr auto;
  }
  .report-container-grid-branchadmin-true-date {
    grid-column: 1/3;
  }
  .h-d-t-g-w-d-d-s-washer {
    grid-template-columns: 5fr 5fr 1fr;
  }
}

@media (max-width: 480px) {
  .customer-view-container-grid {
    grid-gap: 10px;
    font-size: 14px;
    padding-bottom: 10px;
  }
  .customer-view-header-grid {
    width: 100%;
    grid-gap: 5px;
    font-size: 14px;
  }
  .customer-view-header-grid-true {
    grid-template-columns: 1fr 1fr;
  }
  .customer-view-profile-image > .ant-avatar {
    width: 180px;
    height: 180px;
  }
  .customer-view-info {
    grid-gap: 0 20px;
  }

  .h-d-t-g-w-d-d-s-washer {
    grid-template-columns: 5fr 1fr;
  }
}
