.report-container-grid {
  display: grid;
  grid-gap: 10px;
}
.report-container-grid-superadmin-false,
.report-container-grid-superadmin-true,
.report-container-grid-branchadmin-false {
  grid-template-columns: 350px 1fr auto 140px;
}
.report-container-grid-branchadmin-true {
  grid-template-columns: 350px 1fr auto;
}

/* -------------------------------------------------- RESPONSIVE -------------------------------------------------- */
@media (max-width: 1280px) {
  .report-container-grid-superadmin-false,
  .report-container-grid-superadmin-true,
  .report-container-grid-branchadmin-false {
    grid-template-columns: 310px 1fr auto auto;
  }
}

@media (max-width: 1024px) {
  .report-container-grid {
    grid-gap: 5px;
  }
  .report-container-grid-branchadmin-true {
    grid-template-columns: 310px 1fr auto;
  }
}

@media (max-width: 768px) {
  .report-container-grid-superadmin-false,
  .report-container-grid-superadmin-true,
  .report-container-grid-branchadmin-false {
    grid-template-columns: 1fr auto auto;
  }
  .report-container-grid-superadmin-false-date,
  .report-container-grid-superadmin-true-date,
  .report-container-grid-branchadmin-false-date {
    grid-column: 1/4;
  }
}

@media (max-width: 600px) {
  .report-container-grid-branchadmin-true {
    grid-template-columns: 1fr auto;
  }
  .report-container-grid-branchadmin-true-date {
    grid-column: 1/3;
  }
}

@media (max-width: 480px) {
  .report-container-grid {
    font-size: 14px;
  }
  .report-container-grid-superadmin-false,
  .report-container-grid-superadmin-true,
  .report-container-grid-branchadmin-false {
    grid-template-columns: 1fr auto;
  }
  .report-container-grid-superadmin-false-export,
  .report-container-grid-superadmin-true-export,
  .report-container-grid-branchadmin-false-export {
    grid-column: 1/3;
  }
}
