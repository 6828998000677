.table-column-width-phone {
  max-width: 200px;
}
.table-export-btn-width {
  font-size: 16px;
  padding: 0 10px;
  width: 110px;
}
.table-filter-export-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  margin-bottom: 10px;
}

@media (max-width: 480px) {
  .table-export-btn-responsive {
    width: 100%;
    grid-column: 1 / 3;
    padding: 3px 0;
  }
  .table-export-btn-width {
    font-size: 14px;
  }
}
