.navigation-container-false,
.navigation-container-true {
  position: fixed;
  top: 0;
  z-index: 1000;
  display: block;
  /* width: 100%; */
  height: 100vh;
  box-shadow: 1px 0 10px 0 #3f4d67;
  background-color: #3f4d67;
  color: #a9b7d0;
  overflow-x: hidden;
}
.navigation-container-width-false {
  width: 300px;
}
.navigation-container-width-true {
  width: 60px;
}
.navigation-container-transition {
  transition: 0.5s ease-in-out;
}
.navigation-menu-close-container {
  position: fixed;
  z-index: 1111;
}
.navigation-menu-close {
  position: absolute;
  top: 5px;
  height: 35px;
  width: 20px;
  box-shadow: 2px 2px 5px 0 #3f4d67;
  background-color: white;
  border-radius: 0 100px 100px 0;
  cursor: pointer;
  padding-right: 3px;
}
.navigation-menu-close-false {
  left: 300px;
}
.navigation-menu-close-true {
  left: 60px;
}
.navigation-inside-container {
  width: 300px;
}
.navigation-menu-grid {
  display: grid;
  grid-template-columns: 50px 1fr auto;
  grid-gap: 5px;
  height: 60px;
  margin-left: 5px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
.navigation-menu-grid:hover {
  color: white;
}
.navigation-menu-logo {
  color: white;
  cursor: auto;
}
.navigation-menu-image img {
  max-width: 35px;
  max-height: 45px;
}
.navigation-menu-icon {
  font-size: 25px;
  line-height: 0;
}
.navigation-menu-arrow {
  font-size: 16px;
  line-height: 0;
  width: 40px;
  transition: 0.3s ease-in-out;
}
.navigation-menu-arrow-bottom {
  transform: rotate(90deg);
}
.navigation-menu-children-container {
  background-color: #39465e;
  overflow: hidden;
  transition: 0.3s ease-in-out;
}
.navigation-menu-children-grid {
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-gap: 5px;
  height: 40px;
  margin-left: 5px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
.navigation-menu-children-grid:hover {
  color: white;
}

@media (max-width: 1024px) {
  .navigation-container-false {
    width: 60px;
  }
  .navigation-container-false:hover {
    width: 300px;
  }
}
