.coin-collect-search-bar-all-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
  margin: 10px 0 20px;
}
.coin-collect-search-bar-grid {
  display: grid;
  grid-template-columns: 1fr repeat(4, auto) 110px;
  grid-gap: 10px;
}
.coin-collect-add-site-grid {
  display: grid;
  grid-template-columns: 110px 1fr;
  grid-gap: 10px;
}
.coin-collect-add-site-grid-total {
  display: grid;
  grid-template-columns: 150px 1fr;
  grid-gap: 10px;
}
.coin-collect-baht-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
  margin-top: 10px;
}
.coin-collect-text-qr {
  display: grid;
  grid-template-columns: 8fr minmax(50px, 1fr);
  grid-gap: 10px;
}
.coin-collect-btn-scan {
  height: 100%;
  line-height: 0;
  font-size: 20px;
}
.coin-collect-qr-reader {
  width: 400px;
}
.coin-collect-title {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 20px;
}
.coin-collect-card-list {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}
.coin-collect-card-list > div {
  margin: 3px;
}
.coin-collect-card {
  padding: 5px 15px;
}
.coin-collect-card-grid {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 10px;
}

@media (max-width: 1280px) {
  .coin-collect-search-bar-all-grid {
    grid-template-columns: 1fr;
  }
  .coin-collect-search-bar-grid {
    grid-gap: 5px;
  }
}

@media (max-width: 768px) {
  .coin-collect-baht-grid {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
  .coin-collect-title {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
  .coin-collect-card-list {
    justify-content: flex-start;
  }
}

@media (max-width: 600px) {
  .coin-collect-search-bar-grid {
    grid-template-columns: 2fr 2fr 2fr 2fr 1fr;
    font-size: 14px;
  }
  .coin-collect-search-bar-grid-search {
    grid-column: 1/5;
  }
  .coin-collect-search-bar-grid-export {
    grid-column: 4/6;
  }
}

@media (max-width: 480px) {
  .coin-collect-search-bar-grid {
    grid-template-columns: 2fr 1fr 1fr;
  }
  .coin-collect-search-bar-grid-search {
    grid-column: 1/3;
  }
  .coin-collect-search-bar-grid-check {
    grid-column: 2/4;
  }
  .coin-collect-search-bar-grid-count {
    grid-column: 1/2;
  }
  .coin-collect-search-bar-grid-export {
    grid-column: 2/4;
  }
  .coin-collect-add-site-grid {
    grid-template-columns: 1fr;
  }
}
