.dash-report-card-padding {
  padding: 20px 30px;
}
.dash-report-card-payment-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}
.dash-report-payment-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}
.dash-report-payment-text {
  justify-content: left;
}
.dash-report-card-detail-grid {
  display: grid;
  grid-template-columns: 20px 80px 1fr;
  grid-gap: 10px;
}
.dash-report-title {
  font-size: 22px;
  margin-bottom: 10px;
}
.dash-report-total-credit {
  font-size: 30px;
  margin-bottom: 10px;
}
.dash-report-card-payment-detail-grid {
  display: grid;
  grid-template-columns: 30px 1fr auto;
  grid-gap: 10px;
  font-size: 20px;
}

@media (max-width: 1280px) {
  .dash-report-title {
    font-size: 20px;
  }
  .dash-report-total-credit {
    font-size: 28px;
  }
  .dash-report-card-padding {
    padding: 15px 25px;
  }
  .dash-report-card-payment-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 25px;
  }
  .dash-report-card-payment-detail-grid {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .dash-report-title {
    font-size: 16px;
    margin-bottom: 5px;
  }
  .dash-report-card-padding {
    padding: 15px 20px;
  }
  .dash-report-card-detail-grid {
    font-size: 14px;
  }
  .dash-report-total-credit {
    font-size: 22px;
  }
  .dash-report-card-payment-grid {
    grid-gap: 20px;
  }
  .dash-report-card-payment-detail-grid {
    grid-template-columns: 25px 1fr auto;
    grid-gap: 5px;
  }
}

@media (max-width: 600px) {
  .dash-report-card-payment-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .dash-report-card-padding {
    padding: 10px 15px;
  }
  .dash-report-title {
    font-size: 14px;
    margin-bottom: 0px;
  }
  .dash-report-card-detail-grid {
    font-size: 12px;
    grid-template-columns: 20px 70px 1fr;
    grid-gap: 5px;
  }
  .dash-report-total-credit {
    font-size: 18px;
  }
  .dash-report-card-payment-detail-grid {
    font-size: 14px;
  }
  .dash-report-card-payment-grid {
    grid-gap: 10px;
  }
  .dash-chart-title {
    font-size: 14px;
  }
}
