/* -------------------------------------------------- DISCOUNT -------------------------------------------------- */
.site-pro-discount-period-time-grid {
  display: grid;
  grid-template-columns: 120px 1fr 130px;
  grid-gap: 10px;
  max-width: 700px;
  margin-left: 10px;
}
.site-pro-discount-period-time-grid:not(:first-child) {
  margin-top: 5px;
}
.site-pro-discount-period-time-day-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
}
.site-pro-discount-period-time-day-grid i {
  line-height: 0;
}
.site-pro-discount-all-day {
  margin-left: 10px;
}

@media (max-width: 768px) {
  .site-pro-discount-period-time-grid {
    grid-template-columns: 1fr auto;
    grid-gap: 2px 10px;
    margin: 0;
  }
  .site-pro-discount-period-time-grid-day {
    grid-column: 1/3;
    margin-left: 10px;
  }
}

@media (max-width: 600px) {
  /* .site-pro-discount-period-time-grid {
    grid-template-columns: 1fr;
    grid-gap: 2px;
  } */
  .site-pro-discount-period-time-grid-day {
    grid-column: 1/2;
  }
  .site-pro-discount-period-time-grid-time {
    grid-column: 1/3;
  }
  .site-pro-discount-period-time-order-1 {
    order: 1;
  }
  .site-pro-discount-period-time-order-2 {
    order: 2;
  }
  .site-pro-discount-period-time-order-3 {
    order: 3;
  }
}
