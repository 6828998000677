.call-header-grid {
  display: grid;
  grid-template-columns: 11fr 10fr 1fr 6fr 6fr;
  grid-gap: 10px;
  margin-bottom: 20px;
}
.call-go-page-grid {
  display: grid;
  grid-template-columns: 6fr minmax(180px, 1fr);
  grid-gap: 10px;
}
