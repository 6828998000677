.maintenance-header-grid {
  display: grid;
  grid-gap: 10px;
  margin-bottom: 20px;
}
.maintenance-header-grid-support {
  grid-template-columns: repeat(2, 3fr) 5fr repeat(3, auto) 110px;
}
.maintenance-header-grid-offline {
  grid-template-columns: repeat(2, 3fr) 5fr repeat(2, auto) 110px;
}
.maintenance-header-grid-ticket {
  grid-template-columns: repeat(3, 3fr) 5fr repeat(2, auto) 110px;
}
.maintenance-header-grid-history {
  grid-template-columns: repeat(3, 1fr) 2fr 1fr auto 110px;
}

@media (max-width: 1280px) {
  .maintenance-header-grid {
    grid-gap: 5px;
  }
  .maintenance-header-grid-ticket {
    grid-template-columns: 4fr 1fr 3fr 4fr;
  }
  .maintenance-header-grid-ticket-machine {
    grid-column: 2/4;
  }
  .maintenance-header-grid-history {
    grid-template-columns: repeat(2, 5fr) 1fr 4fr;
  }
  .maintenance-header-grid-history-site {
    grid-column: 3/5;
  }
  .maintenance-header-grid-support {
    grid-template-columns: repeat(2, 5fr) 4fr 1fr;
  }
  .maintenance-header-grid-support-export {
    grid-column: 3/5;
  }
}

@media (max-width: 768px) {
  .maintenance-header-grid-history {
    grid-template-columns: 5fr 4fr 1fr 5fr;
  }
  .maintenance-header-grid-history-machine {
    grid-column: 2/4;
  }
  .maintenance-header-grid-history-site {
    grid-column: unset;
  }
  .maintenance-header-grid-history-date {
    grid-column: 1/5;
  }
  .maintenance-header-grid-history-search {
    grid-column: 1/3;
  }
  .maintenance-header-grid-support {
    grid-template-columns: 5fr 4fr 1fr;
  }
  .maintenance-header-grid-support-site,
  .maintenance-header-grid-support-export {
    grid-column: 2/4;
  }
  .maintenance-header-grid-support-search {
    grid-column: 1/3;
  }
  .maintenance-header-grid-support-all {
    grid-column: 1/4;
  }
  .maintenance-header-grid-offline {
    grid-template-columns: 5fr 4fr 1fr;
  }
  .maintenance-header-grid-offline-site,
  .maintenance-header-grid-offline-export {
    grid-column: 2/4;
  }
  .maintenance-header-grid-offline-search {
    grid-column: 1/3;
  }
}

@media (max-width: 480px) {
  .maintenance-header-grid {
    font-size: 14px;
  }
  .maintenance-header-grid-ticket {
    grid-template-columns: 3fr 2fr 1fr;
  }
  .maintenance-header-grid-ticket-machine,
  .maintenance-header-grid-ticket-select {
    grid-column: 1/4;
  }
  .maintenance-header-grid-ticket-search {
    grid-column: 1/3;
  }
  .maintenance-header-grid-ticket-export {
    grid-column: 2/4;
  }
  .maintenance-header-grid-history {
    grid-template-columns: 1fr auto;
  }
  .maintenance-header-grid-history-maintenance,
  .maintenance-header-grid-history-machine,
  .maintenance-header-grid-history-site,
  .maintenance-header-grid-history-date,
  .maintenance-header-grid-history-export {
    grid-column: 1/3;
  }
  .maintenance-header-grid-history-search {
    grid-column: unset;
  }
  .maintenance-header-grid-support-machine,
  .maintenance-header-grid-support-site {
    grid-column: 1/4;
  }
  .maintenance-header-grid-offline-machine,
  .maintenance-header-grid-offline-site {
    grid-column: 1/4;
  }
}
