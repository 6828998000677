.site-manage-get-more-grid {
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-gap: 10px;
}
.site-manage-maps-container {
  height: 400px;
  border: 1px solid #cccccc;
}
.site-manage-maps-pin {
  font-size: 40px;
  color: #ed2228;
  line-height: 0;
  text-shadow: 2px 2px 0 black;
}
.site-manage-maps-pin-input {
  color: #ed2228;
  line-height: 0;
  text-shadow: 1px 1px 0 black;
}
.site-manage-maps-pin i {
  position: absolute;
  top: -40px;
  left: -10px;
}
.site-manage-maps-lat-lng-grid {
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-gap: 0 10px;
  margin-top: 10px;
}


.check-washer{
  margin-top: 15px;
}

.check-washer .ant-checkbox-wrapper{
  font-size: 16px;
}
.size-bag{
  margin-top: 15px;
}