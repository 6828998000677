.sign-in-container {
  height: 100vh;
  background-color: #f4f7fa;
}
.sign-in-container-box {
  max-width: 500px;
  width: 100%;
  padding: 30px;
  border-radius: 40px;
  background-color: white;
  box-shadow: 0 1px 20px 0 #cccccc;
  background-color: white;
}
.sign-in-logo img {
  max-width: 100%;
}
.sign-in-input-icon {
  position: absolute;
  left: 0;
  width: 50px;
  height: 100%;
  color: #9ea2a2;
  font-size: 20px;
  line-height: 0;
}
.sign-in-input {
  appearance: none;
  width: 100%;
  border: none;
  border-radius: 40px;
  box-shadow: inset 5px 5px 5px #dddddd, inset -8px -8px 8px #ffffff;
  background-color: transparent;
  padding: 12px 12px 12px 45px;
  transition: 0.3s ease-in-out;
}
.sign-in-input:focus {
  outline: none;
  box-shadow: inset 5px 5px 5px #cccccc, inset -8px -8px 8px #ffffff;
}
.sign-in-input::placeholder {
  color: #cccccc;
}
.sign-in-button {
  width: 100%;
  color: white;
  background-color: #083b9e;
  padding: 10px;
  border-radius: 40px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.sign-in-button:hover {
  background-color: #0a43b7;
}
